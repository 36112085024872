:root {
    --black-gradient: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #15089d 91.61%
    );
    
    --card-shadow: 0px 20px 100px -10px rgba(66, 69, 83, 0.1);
  }
  
  * {
    scroll-behavior: smooth;
  }
  
  .text-gradient {
    background-image: linear-gradient(to right bottom, #5740e6, #4d36db, #432cd0, #3921c5, #2d15ba, #2710ad, #200ca0, #190794, #180983, #170b72, #160b61, #150b51);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  
  .bg-blue-gradient {
    background-image: linear-gradient(to right bottom, #5740e6, #4d36db, #432cd0, #3921c5, #2d15ba, #2710ad, #200ca0, #190794, #180983, #170b72, #160b61, #150b51);
  }
  
  .bg-black-gradient {
    background: linear-gradient(
      144.39deg,
      #ffffff -278.56%,
      #6d6d6d -78.47%,
      #11101d 91.61%
    );
  }
  
  .bg-black-gradient-2 {
    background: linear-gradient(
      -168.39deg,
      #ffffff -278.56%,
      #898181 -78.47%,
      #353350 91.61%
    );
  }
  
  .bg-gray-gradient {
    background: linear-gradient(
      153.47deg,
      rgba(255, 255, 255, 0) -341.94%,
      #14101d 95.11%
    );
  }
  
  .bg-discount-gradient {
    background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
  }
  
  .box-shadow {
    box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
  }
  
  .sidebar {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
  
  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  
  .feature-card:hover {
    background: var(--black-gradient);
    box-shadow: var(--card-shadow);
  }
  
  .feedback-container .feedback-card:last-child {
    margin-right: 0px;
  }
  
  .feedback-card {
    background: transparent;
  }
  
  .feedback-card:hover {
    background: var(--black-gradient);
  }
  
  .blue__gradient {
    background: linear-gradient(180deg, rgba(62, 4, 236, 0) 0%, #0b0664 100%);
    filter: blur(100px);
  }
  
  .pink__gradient {
    background: linear-gradient(90deg, #015aff48 0%, #1a3ab0 100%,#6586ff 60%);
    filter: blur(100px);
  }
  
  .white__gradient {
    background: rgba(243, 243, 243, 0.6);
    filter: blur(300px);
  }
  .swiper-container {
    border-radius: 5px;
    width: 90vw;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: fit-content;
    height: fit-content;
    font-size: 50px;
    position: relative;
    padding-bottom: 50px;
    font-family: sans-serif;
  }
  
  .swiper-slide {
    width: auto;
    height: fit-content;
    display: flex;
    height: auto;
    border-radius: 15px;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: column;
    padding: 0px;
    font-family: Nunito;
    /* background-color: white; */
  
  }
  
  .swiper-slide .ImgHolder {
    background-color: #ffffff;
    display: flex;
    width: 100%;
    height: auto;
    padding: 30px;
  
  }
  
  .ContentHolder::before {
    background-color: #282828;
    /* content: '';
    width: 50px;
    height: 50px;
    position: absolute;
    top: -5px;
    z-index: -1;
    right: 0px; */
  }
  
  .ContentHolder {
    position: relative;
    padding: 10px;
    background-color: white;
    /* border-top-right-radius: 10px; */
  }
  
  .swiper-pagination-bullet {
    background-color: white;
    opacity: 1;
    border: 1px solid #282828;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #00089f;
  }
  
  .swiper-button {
    border: 1px solid white;
  }
  
  .swiper-slide img {
    width: 100px;
    height: 100px;
    /* border-radius: 50%; */
  
    /* border: 3px solid #282828;
    outline: 2px solid white; */
  }
  
  .swiper-slide h3 {
    font-size: 1.1rem;
    text-align: center;
    font-weight: bold;
  }
  
  .swiper-slide p {
    font-size: 0.9rem;
    padding: 5px;
  }
  
  @media (max-width:638px) {
    .swiper-container {
      width: 100%;
    }
  }
  
  @media (max-width:500px) {
    .swiper-container {
      width: 87vw;
    }
  }
  
  @media (max-width:300px) {
    .swiper-container {
      width: 100%;
    }
  
    .swiper-slide {
      border-radius: 0px;
    }
  
    .swiper-container .ImgHolder {
      border-radius: 0px;
    }
    .ContentHolder{
      border-radius: 0px;
    }
  }
  
  .contact h2 {
      font-size: 45px;
      font-weight: 700;
    margin-bottom: 30px;
  }
  .contact form input, .contact form textarea {
    width: 100%;
    background: rgba(36, 36, 36, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    color: #fff;
    margin: 0 0 8px 0;
    padding: 18px 26px;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.8px;
    transition: 0.3s ease-in-out;
  }
  .contact form input:focus, .contact form textarea:focus {
    background: rgba(255, 255, 255, 1);
    color: #121212;
  }
  .contact form input::placeholder, .contact form textarea::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }
  .contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
    color: #121212;
    opacity: 0.8;
  }